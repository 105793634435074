import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertActionEntityDialogComponent, LoaderService } from 'src/app/shared';
import { PGIService } from 'src/app/shared/services/pgi.service';

@Component({
  selector: 'app-targeted-opportunities',
  templateUrl: './targeted-opportunities.component.html',
  styleUrl: './targeted-opportunities.component.scss',
})
export class TargetedOpportunitiesComponent implements OnInit {

  public targetedOpportunitiesOptions: any = undefined;
  public topAccountsOptions: any = undefined;
  public coverageLimitOptions: any = undefined;
  public bookSegmentationOptions: any = undefined;
  public targetedOpportunitiesLabels: any = [];
  public data: any = undefined;

  @Output() detailsToView: EventEmitter<any> = new EventEmitter();

  constructor(private pgiService: PGIService, private loader: LoaderService, private dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    this.loader.display(true);
    this.data = await this.pgiService.getPGITargetedOpportunities();
    this.loadStackedChart();
    this.topAccounts();
    this.coverageLimit();
    this.bookSegmentation();
    this.loader.display(false);
  }

  private loadStackedChart() {
    let series: any = [];
    let labels: any = [];
    let greens: any = [];
    let reds: any = [];
    let yellows: any = [];
    let strategies: any = [];

    let entries = Object.entries(this.data.opportunities);

    entries.forEach((entry: any) => {
      greens.push((entry[1].green?.percentage || 0) * 100);
      reds.push((entry[1].red?.percentage || 0) * 100);
      yellows.push((entry[1].yellow?.percentage || 0) * 100);

      labels.push(this.countAndGroupWords(entry[1].label));
      strategies.push({ strategy: entry[1].strategy, label: entry[1].label });
    });

    this.targetedOpportunitiesLabels = strategies;

    series = [{
      name: 'Red',
      data: reds
    }, {
      name: 'Green',
      data: greens
    }, {
      name: 'Yellow',
      data: yellows
    }];

    this.targetedOpportunitiesOptions = {
      series: series,
      chart: {
        type: "bar",
        height: 250,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        show: false,
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: labels
      },
      tooltip: {
        y: {
          formatter: (val: any, { seriesIndex, dataPointIndex, w }: any) => {
            // console.log('seriesIndex', seriesIndex, dataPointIndex, w, Object.entries(this.data.opportunities)[dataPointIndex][1])
            let data: any = entries[dataPointIndex][1];

            switch (seriesIndex) {
              case 0:
                return data.red?.count || 0;
                break;
              case 1:
                return data.green?.count || 0;
                break;
              case 2:
                return data.yellow?.count || 0;
                break;
            }
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
    };
  }

  private topAccounts() {
    let series: any = [];
    let labels: any = [];

    Object.entries(this.data.topAccountsByPremiumWeight).forEach((entry: any) => {
      labels.push(entry[1].account);
      series.push(entry[1].percentage);
    });

    // console.log('labels', labels)

    this.topAccountsOptions = {
      series: series,
      chart: {
        type: "pie",
        height: '330',
        toolbar: {
          show: false
        }
      },
      colors: ['#31356d', '#5f3966', '#8a5171', '#b77286', '#e28386', '#fd9274', '#ffa65e', '#ffc45a', '#cadd29', '#56cb17', '#00ad61', '#1c9c74', '#398f7f', '#296e6d', '#2c707e', '#4880ac', '#6de5e7', '#41b8d6', '#3787b9', '#2f5f99'],
      labels: labels,
      dataLabels: {
        enabled: true,
        offsetX: 20,
        offsetY: -20,
        dropShadow: {
          enabled: false
        },
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200
      //       }
      //     }
      //   }
      // ],
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'left'
      },
      stroke: {
        show: false
      }
    };
  }

  private coverageLimit() {
    let data: any = [];
    let labels: any = [];
    let counts: any = [];

    Object.entries(this.data.autoPoliciesByCoverageLimit).forEach((entry: any) => {
      labels.push(entry[0]);
      data.push(entry[1].percentage);
      counts.push(entry[1][""]);
    });
    // console.log(labels, data)

    this.coverageLimitOptions = {
      series: [{
        name: 'Auto Policies by Coverage Limit',
        data: data
      }],
      colors: ['#004bad'],
      chart: {
        type: "bar",
        height: 'auto',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        tickPlacement: 1,
        categories: labels
      },
      tooltip: {
        y: {
          formatter: (val: any, index: any) => {
            return `${val} (${counts[index.dataPointIndex]})`;
          }
        }
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'left'
      },
    };
  }

  private bookSegmentation() {
    let labels: any = [];
    let series: any = [];

    Object.entries(this.data.safecoBookSegmentation).forEach((entry: any) => {
      labels.push(this.capitalize(entry[0]));
      Object.entries(entry[1]).forEach((type: any) => {
        if (!series.find((i: any) => i.name.toLowerCase() == type[0].toLowerCase())) {
          series.push({
            name: this.capitalize(type[0]),
            data: []
          });
        }

        series.map((o: any) => {
          if (o.name.toLowerCase() == type[0].toLowerCase())
            o.data.push(type[1])
        });
      });
    });

    // console.log(labels, series)

    this.bookSegmentationOptions = {
      series: series,
      chart: {
        type: "bar",
        height: 'auto',
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        show: false,
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: labels,
        labels: {
          formatter: (val: any) => {
            return val;
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        }
      },
      tooltip: {
        y: {
          formatter: (val: any) => {
            return val;
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left'
      },
    };
  }

  private capitalize(str: string): string {
    if (!str) return str; // Handle empty or null strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  viewTargetedOpportunitiesDetails(label: any) {
    if (label.strategy == 'Selling Telematics') {//TODO to remove this if
      // console.log(this.data.opportunities)
      let data = {};
      Object.entries(this.data.opportunities).forEach((entry: any) => {
        if (entry[1].strategy.toLowerCase() == label.strategy.toLowerCase()) {
          data = { green: entry[1].green, red: entry[1].red, yellow: entry[1].yellow };
        }
      });
      this.detailsToView.emit({ tab: 'targeted_opportunities', type: label.strategy, data: data, label: label.label });
    }
    else {
      this.viewDetails();
    }
  }

  private countAndGroupWords(text: string): string[] {
    const allWords = text.trim().split(/\s+/);
    let groupedWords = [];

    let tempGroup: string[] = [];
    for (let i = 0; i < allWords.length; i++) {
      const word = allWords[i];
      tempGroup.push(word);

      // Check if the group has two countable words
      if (tempGroup.filter(w => w.length > 2).length === 2) {
        groupedWords.push(tempGroup.join(' '));
        tempGroup = [];
      }
    }

    // Push any remaining words into the last group
    if (tempGroup.length > 0) {
      groupedWords.push(tempGroup.join(' '));
    }

    return groupedWords;
  }

  viewDetails() {
    const dialog = this.dialog.open(AlertActionEntityDialogComponent, {
      data: {
        description: 'This feature is under construction.',
        alertSetting: {
          showCloseButton: true,
          style: { 'color': '#34577f' },
          closeButtonStyle: { 'color': 'white', 'background-color': '#34577f' },
        },
        disableClose: true,
        width: 'fit-content'
      }
    });
  }
}
