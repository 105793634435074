<div fxLayout="column" fxLayoutGap="0">
    <div fxFlex fxLayout="row" fxLayoutGap="0">
        <div fxFlex fxGrow="0" class="back-icon">
            <mat-icon (click)="goBack()">arrow_back</mat-icon>
        </div>
        <span fxFlex fxGrow="1" class="title">{{detailsData.label}}</span>
    </div>
    <!-- <span class="flex-item breadcrumbs">{{detailsData.tab}} > {{detailsData.type}} > View Details</span> -->
    <div fxFlex style="width: 50%;">
        <apx-chart *ngIf="targetedOpportunitiesOptions" [series]="targetedOpportunitiesOptions.series"
            [chart]="targetedOpportunitiesOptions.chart" [plotOptions]="targetedOpportunitiesOptions.plotOptions"
            [xaxis]="targetedOpportunitiesOptions.xaxis" [yaxis]="targetedOpportunitiesOptions.yaxis"
            [stroke]="targetedOpportunitiesOptions.stroke" [fill]="targetedOpportunitiesOptions.fill"
            [tooltip]="targetedOpportunitiesOptions.tooltip" [colors]="targetedOpportunitiesOptions.colors"
            [legend]="targetedOpportunitiesOptions.legend"></apx-chart>
    </div>
    <div fxFlex class="red table-title" *ngIf="selectedIndex == 0">Auto Policies Sold without Telematics Device:
        {{selectedTab.count}} ({{selectedTab.percentage * 100}}%)</div>
    <div fxFlex class="green table-title" *ngIf="selectedIndex == 1">Auto Policies Sold with Telematics Device:
        {{selectedTab.count}} ({{selectedTab.percentage * 100}}%)</div>
    <div fxFlex class="yellow table-title" *ngIf="selectedIndex == 2">Auto Policies Sold with Unknown Telematics Device:
        {{selectedTab.count}} ({{selectedTab.percentage * 100}}%)</div>
    <div fxFlex class="data-table" [hidden]="loading">
        <table mat-table [dataSource]="datasource" class="mat-elevation-z8">
            <ng-container matColumnDef="policy_number">
                <th mat-header-cell *matHeaderCellDef>Policy Number</th>
                <td mat-cell *matCellDef="let element">
                    <a [href]="element.EzLynxPolicyURL" target="_blank">{{element.PolicyNumber || '-'}}</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="insured_name">
                <th mat-header-cell *matHeaderCellDef>Insured Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.Insured_Name || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email Address</th>
                <td mat-cell *matCellDef="let element">
                    {{element.EmailAddress || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="primary_phone">
                <th mat-header-cell *matHeaderCellDef>Primary Phone Number</th>
                <td mat-cell *matCellDef="let element">
                    {{element.PrimaryPhone || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="rating">
                <th mat-header-cell *matHeaderCellDef>Rating State</th>
                <td mat-cell *matCellDef="let element">
                    {{element.RatingStateCode || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="carrier">
                <th mat-header-cell *matHeaderCellDef>Carrier</th>
                <td mat-cell *matCellDef="let element">
                    {{element.CarrierName || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="premium">
                <th mat-header-cell *matHeaderCellDef>Premium</th>
                <td mat-cell *matCellDef="let element">
                    {{element.WrittenPremium || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="term">
                <th mat-header-cell *matHeaderCellDef>Term</th>
                <td mat-cell *matCellDef="let element">
                    {{element.Term || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="issue_date">
                <th mat-header-cell *matHeaderCellDef>Policy Issue Date</th>
                <td mat-cell *matCellDef="let element">
                    {{element.PolicyIssuedDate || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="expiry_date">
                <th mat-header-cell *matHeaderCellDef>Policy Expiry Date</th>
                <td mat-cell *matCellDef="let element">
                    {{element.ExpirationDate || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="assigned_producer">
                <th mat-header-cell *matHeaderCellDef>Assigned Producer</th>
                <td mat-cell *matCellDef="let element">
                    {{element.AssignedProducer || '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="estimated_discount">
                <th mat-header-cell *matHeaderCellDef>Estimated Discount</th>
                <td mat-cell *matCellDef="let element">
                    {{element.EstimatedDiscount || '-'}}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"></tr>

            <tr *matNoDataRow [hidden]="loading && data?.total_records != 0">
                <td colspan="11" style="padding: 2vh; text-align: center;">No results found</td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10,20,30]" showFirstLastButtons class="paginator-shadow"
            [hidden]="!data?.total_records || loading" (page)="getData(selectedTelematics, $event)"
            [pageIndex]="pageIndex" [pageSize]="pageSize">
        </mat-paginator>
    </div>
</div>