<div mat-dialog-content>
    <span class="title">{{'Filters' | translate}}</span>
    <div fxLayout="column" fxLayoutGap="1vh" class="filters" [formGroup]="form">
        <div fxFlex *ngIf="data.isAdmin">
            <mat-form-field>
                <mat-label>{{'Agency' | translate}}</mat-label>
                <mat-select panelClass="dialog-filter" formControlName="agency" multiple>
                    <mat-option *ngFor="let agency of data.filters.agency" [value]="agency">{{ agency }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex *ngIf="data.isAdmin">
            <mat-form-field>
                <mat-label>{{'Agent' | translate}}</mat-label>
                <mat-select panelClass="dialog-filter" formControlName="agent" multiple>
                    <mat-option *ngFor="let agent of data.filters.agent" [value]="agent">{{ agent }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Carrier' | translate}}</mat-label>
                <mat-select panelClass="dialog-filter" formControlName="carrier" multiple>
                    <mat-option *ngFor="let carrier of data.filters.carrier" [value]="carrier">{{ carrier
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'State' | translate}}</mat-label>
                <mat-select panelClass="dialog-filter" formControlName="state" multiple>
                    <mat-option *ngFor="let state of data.filters.state" [value]="state">{{
                        state
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Product Line' | translate}}</mat-label>
                <mat-select panelClass="dialog-filter" formControlName="productline" multiple>
                    <mat-option *ngFor="let line of data.filters.productline" [value]="line">{{
                        line
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Line of Business' | translate}}</mat-label>
                <mat-select panelClass="dialog-filter" formControlName="lob" multiple>
                    <mat-option *ngFor="let lob of data.filters.lob" [value]="lob">{{
                        lob
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Date' | translate}}</mat-label>
                <mat-select panelClass="dialog-filter" formControlName="dateType">
                    <mat-option value="R12">{{'Rolling 12 (R12)' | translate}}</mat-option>
                    <mat-option value="YTD">{{'Year to Date (YTD)' | translate}}</mat-option>
                    <mat-option value="LYTD">{{'Last Year to Date (LYTD)' | translate}}</mat-option>
                    <mat-option value="CUSTOM">{{'Custom Date Range' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex [hidden]="form.get('dateType').value !== 'CUSTOM'">
            <mat-form-field>
                <mat-label>{{'Enter a date range' | translate}}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="startDate" [value]="date" placeholder="Start date" />
                    <input matEndDate formControlName="endDate" [value]="date" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{'Cancel' | translate}}</button>
    <button mat-raised-button color="primary" (click)="applyFilters()" [class.disabled]="!form.valid"
        [disabled]="!form.valid">{{'Apply' |
        translate}}</button>
</div>