<div class="page-container">
  <div fxLayout="row" fxLayoutGap="5vw" fxLayout.md="column" class="section" *ngIf="data" fxLayoutAlign="center start">
    <div fxFlex="60" flexGrow="1" style="width: 100%;" *ngIf="targetedOpportunitiesOptions">
      <div fxLayout="row" fxLayoutGap="1vw" class="title-container">
        <div class="title" fxFlex>
          Targeted Opportunities
        </div>
      </div>
      <div style="width: 100%;">
        <apx-chart [series]="targetedOpportunitiesOptions.series" [chart]="targetedOpportunitiesOptions.chart"
          [plotOptions]="targetedOpportunitiesOptions.plotOptions" [xaxis]="targetedOpportunitiesOptions.xaxis"
          [stroke]="targetedOpportunitiesOptions.stroke" [fill]="targetedOpportunitiesOptions.fill"
          [tooltip]="targetedOpportunitiesOptions.tooltip" [colors]="['#f93537', '#1cc065', '#ffcb0d']"
          [legend]="targetedOpportunitiesOptions.legend"></apx-chart>
      </div>
    </div>
    <div fxFlex>
      <div fxLayout="row" fxLayoutGap="1vw" class="title-container">
        <div fxFlex class="title">
          Business Development Strategies
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="1vw" class="strategies" style="margin-top: 5vh;">
        <div fxFlex fxGrow="0" *ngFor="let label of targetedOpportunitiesLabels"><span
            (click)="viewTargetedOpportunitiesDetails(label)">View
            Details</span> | {{label.strategy}}
        </div>
      </div>
    </div>
  </div>
  <div class="chart-wrapper" fxLayout.md="column" fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="center center">
    <div fxFlex fxLayout="column" fxLayoutGap="0.5vh" fxGrow="1" class="chart-container section"
      *ngIf="topAccountsOptions">
      <div fxFlex id="chart1" class="chart" fxGrow="1">
        <apx-chart [series]="topAccountsOptions.series" [chart]="topAccountsOptions.chart"
          [labels]="topAccountsOptions.labels" [legend]="topAccountsOptions.legend" [stroke]="topAccountsOptions.stroke"
          [dataLabels]="topAccountsOptions.dataLabels" [colors]="topAccountsOptions.colors"></apx-chart>
      </div>
      <div fxFlex class="details" fxGrow="0" (click)="viewDetails()">View Details</div>
      <div fxFlex class="chart-title" fxGrow="0">Top 20 Accounts by Premium Weight</div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="0.5vh" fxGrow="1" class="chart-container section"
      *ngIf="coverageLimitOptions">
      <div fxFlex id="chart2" class="chart" fxGrow="1">
        <apx-chart [series]="coverageLimitOptions.series" [chart]="coverageLimitOptions.chart"
          [dataLabels]="coverageLimitOptions.dataLabels" [colors]="coverageLimitOptions.colors"
          [tooltip]="coverageLimitOptions.tooltip" [plotOptions]="coverageLimitOptions.plotOptions"
          [legend]="coverageLimitOptions.legend" [xaxis]="coverageLimitOptions.xaxis"></apx-chart>
      </div>
      <div fxFlex class="details" fxGrow="0" (click)="viewDetails()">View Details</div>
      <div fxFlex class="chart-title" fxGrow="0">Auto Policies by Coverage Limit</div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="0.5vh" fxGrow="1" class="chart-container section"
      *ngIf="bookSegmentationOptions">
      <div fxFlex id="chart3" class="chart" fxGrow="1">
        <apx-chart [series]="bookSegmentationOptions.series" [chart]="bookSegmentationOptions.chart"
          [dataLabels]="bookSegmentationOptions.dataLabels" [plotOptions]="bookSegmentationOptions.plotOptions"
          [xaxis]="bookSegmentationOptions.xaxis" [stroke]="bookSegmentationOptions.stroke"
          [fill]="bookSegmentationOptions.fill" [yaxis]="bookSegmentationOptions.yaxis"
          [tooltip]="bookSegmentationOptions.tooltip" [colors]="['#6de7e2', '#4bb8d3', '#368ab9', '#536d99', '#63588f']"
          [legend]="bookSegmentationOptions.legend"></apx-chart>
      </div>
      <div fxFlex class="details" fxGrow="0" (click)="viewDetails()">View Details</div>
      <div fxFlex class="chart-title" fxGrow="0">Safeco Book Segmentation</div>
    </div>
  </div>
</div>