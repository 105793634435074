import { Component, OnInit, Inject } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "src/app/shared";
import { PGIService } from "src/app/shared/services/pgi.service";

interface DialogData {
    loggedInUserType: number;
    filters: any;
    activeFilters: any;
    isAdmin: boolean;
}

@Component({
    selector: 'app-charts-filter-dialog',
    templateUrl: './charts-filter-dialog.component.html',
    styleUrls: ['./charts-filter-dialog.component.scss']
})
export class ChartsFilterDialogComponent implements OnInit {

    public form: FormGroup;
    public date: Date = new Date();

    private allAgencies: any = [];

    constructor(private translate: TranslateService, public dialogRef: MatDialogRef<ChartsFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private pgiService: PGIService, private loader: LoaderService) {
        this.form = new FormGroup({
            agency: new FormControl(this.data.activeFilters.agency || ''),
            agent: new FormControl(this.data.activeFilters.agent || ''),
            carrier: new FormControl(this.data.activeFilters.carrier || ''),
            state: new FormControl(this.data.activeFilters.state || ''),
            productline: new FormControl(this.data.activeFilters.productline || ''),
            lob: new FormControl(this.data.activeFilters.lob || ''),
            dateType: new FormControl(this.data.activeFilters.dateType || ''),
            startDate: new FormControl(this.data.activeFilters.startDate || ''),
            endDate: new FormControl(this.data.activeFilters.endDate || ''),
        }, { validators: this.dateRangeValidator() });

        this.allAgencies = this.data.filters.agency;
    }

    ngOnInit(): void {
        this.form.controls.agency.valueChanges.subscribe(value => {
            this.getFilters(this.form);
        });

        this.form.controls.agent.valueChanges.subscribe(value => {
            this.getFilters(this.form, this.data.activeFilters.agency.length > 1 ? false : true);
        });

        this.form.controls.carrier.valueChanges.subscribe(value => {
            this.getFilters(this.form);
        });

        this.form.controls.state.valueChanges.subscribe(value => {
            this.getFilters(this.form);
        });

        this.form.controls.lob.valueChanges.subscribe(value => {
            this.getFilters(this.form);
        });

        this.form.controls.productline.valueChanges.subscribe(value => {
            this.getFilters(this.form);
        });
    }

    private async getFilters(form: FormGroup, updateAgencies: boolean = false) {
        this.loader.display(true);
        let agency = form.controls.agency.getRawValue().filter(i => i != 'All Agencies');
        let lob = form.controls.lob.getRawValue().filter(i => i != 'All LOBs');
        let state = form.controls.state.getRawValue().filter(i => i != 'All States');
        let productline = form.controls.productline.getRawValue().filter(i => i != 'All Product Lines');
        let agent = form.controls.agent.getRawValue().filter(i => i != 'All Agents');
        let carrier = form.controls.carrier.getRawValue().filter(i => i != 'All Carriers');

        if (agency.length == 0) {
            agency = undefined;
        }

        // if (agent.length && agency.length != 0) {
        //     agent = [];
        // }

        let pgiFiltersData = await this.pgiService.getPGIOverviewFilter(agency, lob, state, productline, agent, carrier);
        if (pgiFiltersData && pgiFiltersData.filters) {
            this.data.filters = {
                agency: updateAgencies ? pgiFiltersData.filters.Agency.concat(this.form.get('agency').value).filter((item, index, array) => array.indexOf(item) === index) : this.allAgencies,
                lob: pgiFiltersData.filters.LOB.concat(this.form.get('lob').value).filter((item, index, array) => array.indexOf(item) === index),
                state: pgiFiltersData.filters.AgencyState.concat(this.form.get('state').value).filter((item, index, array) => array.indexOf(item) === index),
                productline: pgiFiltersData.filters.ProductLine.concat(this.form.get('productline').value).filter((item, index, array) => array.indexOf(item) === index),
                agent: pgiFiltersData.filters.Agent.concat(this.form.get('agent').value).filter((item, index, array) => array.indexOf(item) === index),
                carrier: pgiFiltersData.filters.CarrierName.concat(this.form.get('carrier').value).filter((item, index, array) => array.indexOf(item) === index),
            };
        }
        this.loader.display(false);
    }

    dateRangeValidator(): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            const start = formGroup.get('startDate')?.value;
            const end = formGroup.get('endDate')?.value;
            return start && end && start > end ? { 'invalidDateRange': true } : null;
        };
    }

    applyFilters() {
        this.dialogRef.close({
            agency: this.form.get('agency').value.filter(i => i != 'All Agencies'),
            agent: this.form.get('agent').value.filter(i => i != 'All Agents'),
            carrier: this.form.get('carrier').value.filter(i => i != 'All Carriers'),
            state: this.form.get('state').value.filter(i => i != 'All States'),
            productline: this.form.get('productline').value.filter(i => i != 'All Product Lines'),
            lob: this.form.get('lob').value.filter(i => i != 'All LOBs'),
            dateType: this.form.get('dateType').value,
            startDate: this.form.get('startDate').value,
            endDate: this.form.get('endDate').value,
        });
    }
}