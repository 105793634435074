import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator, MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import moment from 'moment';
import { LoaderService } from "src/app/shared";
import { CustomPaginator } from "src/app/shared/helpers/functions";
import { PGIService } from "src/app/shared/services/pgi.service";

export interface Detail {
    PolicyNumber: any;
    EzLynxPolicyURL: string;
    Insured_Name: string;
    PrimaryPhone: string;
    EmailAddress: string;
    RatingStateCode: string;
    CarrierName: string;
    WrittenPremium: string;
    Term: string;
    PolicyIssuedDate: string;
    ExpirationDate: string;
    AssignedProducer: string;
    EstimatedDiscount: string;
}

@Component({
    selector: 'app-opportunity-details',
    templateUrl: './opportunity-details.component.html',
    styleUrl: './opportunity-details.component.scss',
    providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }]
})
export class OpportunityDetailsComponent implements OnInit {

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    @Output() goBackEmit: EventEmitter<boolean> = new EventEmitter(false);
    @Input() detailsData: any = {};
    // {
    //     "tab": "Targeted Opportunities",
    //     "type": "Selling Telematics",
    //     "data": {
    //         "green": {
    //             "count": 49,
    //             "percentage": 0.33
    //         },
    //         "red": {
    //             "count": 78,
    //             "percentage": 0.52
    //         },
    //         "yellow": {
    //             "count": 23,
    //             "percentage": 0.15
    //         }
    //     },
    //     "label": "Telematics Sales"
    // };

    public datasource: any = [];
    public displayedColumns: string[] = ['policy_number', 'insured_name', 'email', 'primary_phone', 'rating', 'carrier', 'premium', 'term', 'issue_date', 'expiry_date', 'assigned_producer', 'estimated_discount'];
    public pageIndex: number = 1;
    public pageSize: number = 10;
    public targetedOpportunitiesOptions: any = {};
    public selectedIndex: number = 0;
    public loading: boolean = false;
    public selectedTab: any = {};
    public data: any = undefined;
    public selectedTelematics: string = 'red';

    constructor(private changeDetector: ChangeDetectorRef, private pgiService: PGIService, private loader: LoaderService) { }

    ngOnInit(): void {
        // console.log(this.detailsData)
        this.loadStackedChart();
        // this.getData();
        this.pgiService.getOpportunities().subscribe(data => {
            this.data = data;
            this.data.data.map((i: any) => {
                i.ExpirationDate = moment(i.ExpirationDate, 'YYYYMMDD').format('YYYY-MM-DD');
                i.PolicyIssuedDate = moment(i.PolicyIssuedDate, 'YYYYMMDD').format('YYYY-MM-DD');
            });

            this.datasource = new MatTableDataSource<Detail>(this.data.data);
            if (this.paginator)
                this.paginator.length = this.data.total_records;
        });
    }

    private loadStackedChart() {
        let series: any = [];
        let greens: any = [this.detailsData.data.green?.percentage || 0];
        let reds: any = [this.detailsData.data.red?.percentage || 0];
        let yellows: any = [this.detailsData.data.yellow?.percentage || 0];

        series = [{
            name: 'Red',
            data: reds
        }, {
            name: 'Green',
            data: greens
        }, {
            name: 'Yellow',
            data: yellows
        }];

        // console.log(series, this.detailsData)
        this.targetedOpportunitiesOptions = {
            series: series,
            chart: {
                type: "bar",
                height: 80,
                stacked: true,
                stackType: "100%",
                toolbar: {
                    show: false
                },
                events: {
                    dataPointSelection: (event: any, chartContext: any, { seriesIndex, dataPointIndex, config }: any) => {
                        this.showDetails(seriesIndex);
                        // const seriesName = config.series[seriesIndex].name;
                        // const selectedPercentage = config.series[seriesIndex].data[dataPointIndex];
                        // const count = this.getCountBySeriesAndIndex(seriesName, dataPointIndex);
                        // console.log(`You clicked on ${seriesName} - Percentage: ${selectedPercentage}%,`);
                        // Handle your click logic here
                    }
                }
            },
            colors: ['#f93537', '#1cc065', '#ffcb0d'],
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            stroke: {
                width: 1,
                colors: ["#fff"]
            },
            xaxis: {
                labels: {
                    show: false
                },
                categories: [this.detailsData.label]
            },
            yaxis: {
                labels: {
                    show: false
                },
                // categories: [this.detailsData.label]
            },
            tooltip: {
                y: {
                    formatter: (val: any, { seriesIndex, dataPointIndex, w }: any) => {
                        // console.log('seriesIndex', seriesIndex, dataPointIndex, w, Object.entries(this.data.opportunities)[dataPointIndex][1])

                        switch (seriesIndex) {
                            case 0:
                                return this.detailsData.data.red?.count || 0;
                                break;
                            case 1:
                                return this.detailsData.data.green?.count || 0;
                                break;
                            case 2:
                                return this.detailsData.data.yellow?.count || 0;
                                break;
                        }
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                show: false
            },
        };

        this.selectedTab = this.detailsData.data.red;
    }

    private showDetails(index: number) {
        this.selectedIndex = index;
        // console.log(index);
        switch (index) {
            case 0:
                this.displayedColumns.push('estimated_discount');
                this.selectedTelematics = 'red';
                this.selectedTab = this.detailsData.data.red;
                this.getData('red');
                break;
            case 1:
                this.displayedColumns = this.displayedColumns.filter(i => i != 'estimated_discount');
                this.selectedTelematics = 'green';
                this.selectedTab = this.detailsData.data.green;
                this.getData('green');
                break;
            case 2:
                this.displayedColumns = this.displayedColumns.filter(i => i != 'estimated_discount');
                this.selectedTelematics = 'yellow';
                this.selectedTab = this.detailsData.data.yellow;
                this.getData('yellow');
                break;
        }
        this.changeDetector.detectChanges();
    }

    async getData(telematics: string = this.selectedTelematics, event?: PageEvent) {
        this.loader.display(true);
        this.loading = true;
        this.pageIndex = event?.pageIndex || 1;
        this.pageSize = event?.pageSize || this.pageSize;

        this.data = await this.pgiService.getPGITelematicsDetails(this.pageIndex, this.pageSize, telematics);
        if (this.data.total_records) {
            this.data.data.map((i: any) => {
                i.ExpirationDate = moment(i.ExpirationDate, 'YYYYMMDD').format('YYYY-MM-DD');
                i.PolicyIssuedDate = moment(i.PolicyIssuedDate, 'YYYYMMDD').format('YYYY-MM-DD');
            });

            this.datasource = new MatTableDataSource<Detail>(this.data.data);
            if (this.paginator)
                this.paginator.length = this.data.total_records;
        }
        this.loader.display(false);
        this.loading = false;
        // this.paginator.length = data?.pagination.total || 0;
    }

    goBack() {
        this.goBackEmit.emit(true);
    }
}